@import 'styles/colors.scss';

.two-column-images-wrapper {
  background-color: $pageBg;
  padding: 40px 0;
}

.two-column-images {
  max-width: 1200px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
  }

  @media (min-width: 1240px) {
    margin: 0 auto;
  }
}

.two-column-image {
  width: 100%;
  display: block;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border: #ddd solid 1px;

  @media (min-width: 767px) {
    width: 50%;
  }
}

.pitpay.two-column-image {
  width: 100%;
  display: block;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border: #ddd solid 1px;
  width: auto;
  height: 600px;

  @media (min-width: 767px) {
    width: auto;

    height: 600px;
  }
}
