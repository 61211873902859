@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.project-hero {
  position: relative;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 200px;

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    padding-top: 0;
    height: 700px;
  }
}

.project-hero-text {
  width: 100%;

  @media (min-width: 1000px) {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
  }
}

.project-text-wrapper {
  padding: 0 40px;

  @media (min-width: 1000px) {
    max-width: 450px;
    padding: 0;
    width: 100%;
  }
}

.project-title {
  color: $black;
  font-family: $heading;
  line-height: 1.4;
  font-size: 36px;
  margin: 0 0 20px 0;

  @media (min-width: 980px) {
    font-size: 48px;
  }
}

.project-subtitle {
  color: $textGrey;
  font-family: $text;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0;
  padding-top: 15px;
  border-top: 1px solid #eee;

  @media (min-width: 980px) {
    font-size: 20px;
  }
}

.project-hero-image-wrapper {
  width: 100%;

  @media (min-width: 1000px) {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.project-hero-image {
  width: 60%;
  display: block;
  margin: 20px auto 0;

  @media (min-width: 1000px) {
    max-width: 540px;
    display: block;
    margin: 0;
    width: 100%;
  }
}

.pitpay.project-hero-image {
  width: 60%;
  display: block;
  margin: 20px auto 0;

  @media (min-width: 1000px) {
    max-width: 540px;
    display: block;
    margin: 0;
    width: auto;
    height: 500px;
  }
}
