@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.footer {
  background-color: $brandPrimaryPink;
  padding: 20px;
  width: 100%;
  border-top: 10px solid $white;
  text-align: center;

  @media (min-width: 767px) {
    padding: 40px;
  }
}

.footer-navigation {
  text-align: center;

  @media (min-width: 767px) {
    text-align: left;
  }
}

.footer-link {
  font-family: $text;
  font-weight: 500;
  color: $white;
  text-decoration: none;
  margin: 0 20px;
  font-size: 16px;
}
