@import 'styles/colors.scss';

.page-project-text-container {
  background-color: $pageBg;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 40px 0;

  @media (min-width: 767px) {
    padding: 80px 0;
  }
}
