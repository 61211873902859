@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.text-container {
  max-width: 840px;
  width: 100%;
  padding: 0 20px 40px 20px;
  margin: 0 auto;

  @media (min-width: 767px) {
    padding: 0 20px 80px 20px;
  }
}

.heading {
  margin: 0 0 10px 0;
  font-size: 30px;
  color: $black;
  font-weight: 700;
  line-height: 1.3;
  font-family: $heading;

  @media (min-width: 768px) {
    margin: 0 0 20px 0;
    font-size: 40px;
    line-height: 1.8;
  }
}

.text {
  font-size: 14px;
  line-height: 1.75;
  font-weight: 500;
  color: #666;
  font-family: $text;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.75;
  }
}
