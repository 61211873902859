@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.projects-wrapper {
  width: 100%;
  height: 100%;
  padding: 80px 0;
  background-color: #9b1243;
}

.projects-list {
  display: grid;
  list-style: none;
  padding: 0;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 840px;
  margin: 0 auto;
  padding: 0 20px;
}

.project {
  position: relative;
}

.project-link {
  display: block;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;
    transform: skew(-45deg) scaleX(0);
    background-color: $white;
  }

  &:hover {
    &:after {
      -webkit-transform: skew(-45deg) scaleX(1);
      transform: skew(-45deg) scaleX(1);
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .project-caption {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
}

.project-image {
  width: 100%;
  display: block;
}

.project-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.project-caption-title {
  color: black;
  margin: 0 0 10px 0;
  border-bottom: 1px solid black;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  font-family: $heading;
  letter-spacing: 2px;
}

.project-caption-text {
  color: black;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: $text;
}
