@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  border: 1px solid $brandPrimaryPink;
  padding: 10px;
  margin: 20px 0;

  img {
    width: 40px;
    display: block;
  }

  @media (min-width: 768px) {
    padding: 15px;
    border: 3px solid $brandPrimaryPink;
    transition: border-color 0.25s;

    &:hover {
      border-color: #d81a5e;
    }

    img {
      width: 50px;
      display: block;
    }
  }
}

.navigation {
  display: flex;

  &.navigation-inverted {
    .menu-link {
      color: $brandPrimaryPink;
      &:hover {
        color: $black;
      }
    }
  }
}

.menu-link {
  font-size: 18px;
  font-family: $heading;
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: color 0.25s;
  position: relative;
  display: inline-block;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: $white;
  margin-left: 10px;
  letter-spacing: 2px;
  padding: 0 0 5px 0;

  @media (min-width: 767px) {
    font-size: 36px;
    margin-left: 20px;
  }

  &:hover {
    color: #d81a5e;
  }
}
