@import 'styles/colors.scss';
@import 'styles/font-family.scss';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

#root {
  height: 100%;
}

.container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.page {
  height: 100%;
}

.content {
  max-width: 840px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.text {
  p {
    font-size: 18px;
    line-height: 2;
    font-weight: 500;
    color: $textGrey;
    font-family: $text;
    margin: 0;

    a {
      text-decoration: none;
      color: $brandPrimaryPink;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    list-style: disc;
    color: $textGrey;
    line-height: 1.5;
    padding: 0 0 0 20px;
    font-family: $text;
    font-weight: 500;
    font-size: 16px;
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 30px;
    color: $black;
    font-weight: 700;
    line-height: 1.3;
    font-family: $heading;

    @media (min-width: 768px) {
      margin: 0 0 20px 0;
      font-size: 40px;
      line-height: 1.2;
    }
  }

  h4 {
    margin: 20px 0 10px 0;
    font-size: 28px;
    color: $black;
    font-weight: 700;
    line-height: 1.3;
    font-family: $heading;

    @media (min-width: 768px) {
      margin: 40px 0 20px 0;
      font-size: 38px;
      line-height: 1.2;
    }
  }

  .gif {
    max-width: 600px;
    width: 100%;
    margin: 40px auto;
    display: block;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border: #ddd solid 1px;
  }

  .image-container {
    max-width: 800px;
    margin: 40px auto;

    img {
      width: 100%;
      display: block;
    }
  }
}

.button {
  padding: 0 20px;
  border: none;
  color: $white;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
  background: linear-gradient(to right, #f92672, #f0afc9);
  line-height: 40px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
}

.site-link {
  text-decoration: none;
  font-family: $heading;
  color: $brandPrimaryPink;
  font-size: 30px;
  border: 2px solid $brandPrimaryPink;
  display: inline-block;
  width: auto;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 30px;
  line-height: 1;
  transition: color 0.25s;
  position: relative;
  z-index: 1;

  @media (min-width: 767px) {
    &:hover {
      color: $white;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      transition: width 0.25s, background-color 1s;
      width: 0%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
    }

    &:hover {
      &:after {
        transition: all 0.25s;
        background-color: $brandPrimaryPink;
        width: 100%;
      }
    }
  }
}
