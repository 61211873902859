@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.hero-wrapper {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  margin-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $black;
    opacity: 0.8;
  }

  @media (min-width: 767px) {
    margin-bottom: 80px;
    height: 100vh;
  }
}

.hero-text-wrapper {
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 2;
  width: 100%;
  padding: 0 20px;

  @media (min-width: 767px) {
    max-width: 800px;
  }
}

.hero-text {
  color: #d81a5e;
  line-height: 1.5;
  font-family: $heading;
  font-weight: 700;
  font-size: 30px;

  span {
    color: $white;
    font-weight: 700;
  }

  @media (min-width: 767px) {
    font-size: 42px;

    span {
      color: $white;
      font-size: 44px;
    }
  }
}
