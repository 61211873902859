@import 'styles/colors.scss';
@import 'styles/font-family.scss';

.menu-item {
  position: relative;
}

.drop-down__menu-box {
  position: absolute;
  width: 140px;
  left: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  @media (min-width: 767px) {
    width: calc(100% - 20px);
    left: 20px;
  }

  &:after {
    content: '';
    background-color: transparent;
  }
}

.drop-down__menu {
  margin: 0;
  padding: 0 13px;
  list-style: none;
}

.drop-down-bar {
  position: absolute;
  width: 3px;
  height: 28px;
  background-color: $brandPrimaryPink;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
}

.drop-down__item {
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #e0e2e9;

  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $text;
    text-align: left;
    font-weight: 500;
    color: #909dc2;
    display: block;
    transition: color 0.25s;
    padding: 10px 0;
  }
}
.drop-down-hover {
  a {
    color: $brandPrimaryPink;
  }
  @media (hover: hover) {
    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 28px;
      background-color: $brandPrimaryPink;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
